<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions" :head-title="$t(`cip.dig.guration.title.indexHeadTitle`)"
                 @head-add-tabs="headAddTabs">
    </head-layout>
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"
    >
    </grid-head-layout>
    <div class="outWardBox">
      <div class="inWardBoxDevice" v-for="(item,index) in tableData" :key="index">
        <div style="padding: 16px 16px 0 16px">
          <span class="titleBox" v-if="item.name!=null">{{ item.name }}</span>
          <div style="margin: 15px 0;">
            <div class="cardContentBox">
              <div class="contentImgBox">
                <el-image
                  style="max-width: 100%;height: 325px;"
                  :src=item.imageUrl
                  :preview-src-list=item.imageUrl>
<!--                  <div style="width: 100%; height: 100%" slot="error" class="image-slot" >-->
<!--                    <i class="el-icon-picture-outline"></i>-->
<!--                  </div>-->
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomBox" style="display: flex;justify-content: space-between;">
          <div style="font-size: 15px; display: flex; line-height: 28px" v-if="item.isEnable == 2">
            <div class="stateBox"></div>
            {{ $t(`cip.dig.guration.field.notEnabled`) }}
          </div>
          <div style="font-size: 15px; display: flex;line-height: 28px" v-if="item.isEnable == 1">
            <div class="stateBox" style="background-color: #00a680"></div>
            {{ $t(`cip.dig.guration.field.enable`) }}
          </div>
          <div style="font-size: 15px; display: flex; line-height: 28px">
            <div class="timeBox"></div>
            {{item.updateTime}}
          </div>
          <div>
            <el-button
              round
              @click="invalid(item)"
              size="small"
              class="el-icon-view"
            >
            </el-button>
            <el-button
              round
              @click="rowEdit(item)"
              size="small"
              class="el-icon-edit"
            >
            </el-button>
            <el-button
              round
              @click="remove(item)"
              size="small"
              class="el-icon-delete"
            >
            </el-button>
          </div>
        </div>
      </div>
      <div class="inWardBoxDevice">
          <el-button
            @click="headAddTabs()"
            size="small"
            class="el-icon-plus addBtn"
          >
          </el-button>
      </div>
    </div>
    <div class="deviceCard">
      <div></div>
      <el-pagination :total="page.total" layout="total, prev, pager,sizes, next, jumper"
                     :current-page="page.currentPage"
                     background :page-sizes="[7,15,23]"
                     :page-size="page.pageSize" @current-change="currentChange" @size-change="seizechange"/>
    </div>
<!--    <el-dialog :visible.sync="showdevice" :title="$t('cip.cps.device.field.select')" width="60%"-->
<!--               class="avue-dialog avue-dialog&#45;&#45;top" append-to-body v-dialog-drag>-->
<!--      <device-->
<!--        ref="device"-->
<!--        v-if="showdevice"-->
<!--        :protocolType="protocolType"-->
<!--        @deviceData="getDeviceByDevicePopuos"-->
<!--        @devicecancel="getdevicecancel"-->
<!--      ></device>-->
<!--    </el-dialog>-->

  </div>
</template>
<script>
import Button from "@/views/plugin/workflow/design/button";
import {
  remove,
  getList,
} from '@/api/system/gutation'
import Template from "@/views/message/template/list";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {mapGetters} from "vuex";

export default {
  components: {
    Template,
    Button
  },
  name: 'deviceCard',
  inject: ['onLoad'],
  image:"",
  props: {},
  data() {
    return {
      tableData: [],
      showdevice: false,
      protocolType: 'monitoring',
      page: {
        pageSize: 7,
        currentPage: 1,
        total: 0
      },
      query: {
        identification: 1
      },
      headBtnOptions: [
        // {
        //   label: this.$t('cip.cmn.btn.addBtn'),
        //   emit: "head-add-tabs",
        //   type: "button",
        //   icon: "",
        //   btnOptType: 'add',
        // },
      ],
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "name",
          placeholder:this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.dig.guration.field.name`)
        },

      ]
    },

    gridHeadBtn() {
      return []
    },
  },
created() {
  this.image = "http://172.28.20.69:9002/images/upload/20230417/39a4d4b4a4a3e8d1bdc7a800df5cb847.jpeg"

},
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    getdevicecancel() {
      this.showdevice = false
    },
    getDeviceByDevicePopuos(val) {
      // this.tableData = this.tableData.concat(val)
      this.onLoad(this.page, {})
      this.showdevice = false
    },
    handDevice() {
      this.showdevice = true
    },
    searchReset(params) {
      this.query = params;
      this.treeCode = '';
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    //组态新增
    headAddTabs() {
      window.open(process.env.VUE_APP_BASE_WEBURL + '/hmi/#/HmiEdit', '_blank')
    },
    //组态编辑
    rowEdit(row) {
      window.open(process.env.VUE_APP_BASE_WEBURL + '/hmi/#/HmiEdit?id='+row.id, '_blank')
    },
    //组态查看
    invalid(row) {
      window.open(process.env.VUE_APP_BASE_WEBURL + '/hmi/#/HmiView?id='+row.id, '_blank')
    },
    //编辑
    remove(item) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineRemoveWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning"
      })
        .then(() => {
          return remove(item.id, 1);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    currentChange(page) {
      this.page.currentPage = page;
      this.onLoad(this.page.currentPage);
    },
    seizechange(page) {
      this.page.pageSize = page;
      this.onLoad(this.page.pageSize);
    },
    onLoad(page, params = {}) {
      this.query.identification = 1
      getList(this.page.currentPage, this.page.pageSize, Object.assign(params, this.query), this.treeCodeLoc, this.treeCodeNet).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
      });
    }
  },
}
</script>
<style scoped>
.addBtn {
  width: 100%;
  height: 455px;
  font-size: 100px;
}
.outWardBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 280px);
  overflow: auto;
  align-content: flex-start;
  display: flex;
}

.cardContentBox {
  width: 100%;
  height: 100%;
  /*display: flex;*/
  /*justify-content: space-between;*/
}

.contentDetails {
  font-size: 15px;
  color: #666666;
  flex-shrink: 0;
}

.contentDetails div {
  display: flex;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  width: 230px;
  text-align: left;
}

.contentDetails span {
  /*width: 100px;*/
  /*text-align: right;*/
  display: block;
}
 .buttonBox{
   width: 100%;
   height: 100%;
 }

.inWardBoxDevice {
  width: 24%;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px #dde9ef;
  border: 1px solid #e7eff3;
  margin-top: 16px;
  margin-right: 0.2%;
  margin-bottom: 0px;
  margin-left: 0.2%;
  text-align: center;
  border-radius: 10px;
  /*height: 50%;*/
  overflow: hidden;
  position: relative;
}

.labelBox {
  position: absolute;
  top: 16px;
  right: 16px;
}

.titleBox {
  font-size: 20px;
  color: #333333;
  font-weight: bolder;
  display: block;
}

.contentBox {
  color: #999999;
  font-size: 12px;
  text-align: center;
}

.contentBox div {
  width: 100px;
  text-align: right;
}

.bottomBox {
  width: calc(100% - 16px);
  /*background-color: rgba(64, 158, 255, 0.55);*/
  border: 2px solid #f4f4f5;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  justify-content: space-between;
}

.stateBox {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin: 10px 6px;
}
.timeBox {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 10px 6px;
}

.el-dropdown + .el-dropdown {
  margin-left: 15px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.deviceCard {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
  /*position: absolute;*/
  /*bottom: 2%;*/
  /*right: 2%;*/
}


th, td {
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 12px;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}


.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

</style>
